import React from "react"
import Banner from "./Banner"
import Overview from "./Overview"
import Introduction from "./Introduction"
import Approach from "./Approach"
import SliderPage from "./Slider"
import Results from "./Results"
import Services from "./Services"

const CaseStudyDetails = ({ data }) => {

  const { confidentcrm_contents: { projectOutcomes, solution } } = data[0]



  return (
    <>
      <Banner data={data} />
      <Overview data={data} />
      <Introduction data={data} />
      <Approach data={data} />
      <SliderPage data={data} />
      <Results data={data} />
      <Services data={data} />
    </>

  )
}

export default CaseStudyDetails
