import React from 'react'
import { useSelector } from 'react-redux'

const Results = ({ data }) => {
  const { language } = useSelector(state => state.global)

  const dummyData={
    "title": "Result We Achieved",
    "caption": "The implementation of the Customer Data Platform yielded remarkable results for our client, revolutionizing their marketing efforts",
    "items": [
        {
            "title": "Increased Lead Generation",
            "metric": "Number of leads generated",
            "outcome": "Confident Group experienced a significant increase in lead generation, witnessing a 25% growth.",
            "image":"https://webdurawdfeed.s3.ap-south-1.amazonaws.com/imgtech/2023/07/06093740/attract-customers-icon-1.png"
        },
        {
            "title": "Improved Lead Conversion",
            "metric": "Conversion rate",
            "outcome": "The lead conversion rate improved by 15%, enabling the agency to convert more leads into successful deals.",
            "image":"https://webdurawdfeed.s3.ap-south-1.amazonaws.com/imgtech/2023/07/06093739/lead-generation-icon-1.png"
        },
        {
            "title": "Higher Marketing ROI",
            "metric": "ROI on marketing spend",
            "outcome": "Confident Group achieved a remarkable 20% increase in marketing ROI, optimizing their marketing campaigns for better returns.",
            "image":"https://webdurawdfeed.s3.ap-south-1.amazonaws.com/imgtech/2023/07/06093739/hand-money-income-dollar-icon-1.png"
        },
        {
            "title": "Increased Customer Satisfaction",
            "metric": "Customer satisfaction score",
            "outcome": "The customer satisfaction score improved by 10%, reflecting the agency's ability to meet and exceed customer expectations.",
            "image":"https://webdurawdfeed.s3.ap-south-1.amazonaws.com/imgtech/2023/07/06093738/recommendation-icon-1.png"
        },
        {
            "title": "Improved Agent Productivity",
            "metric": "Average number of deals closed per agent",
            "outcome": "The average number of deals closed per agent increased by 5%, boosting overall agent productivity and performance.",
            "image":"https://webdurawdfeed.s3.ap-south-1.amazonaws.com/imgtech/2023/07/06093740/chart-arrow-up-icon-1.png"
        }

    ]
}

  const { casestudy_details: { results: { title, text } } } = data[0]
  return (
    <section className="broughtset">
      <h2>
        {title[language]}
      </h2>
      <div className="overblock resultsblock">
        {text ? <p>{text[language]}</p> : null}
      </div>

      <div className="overblock resultsblock">
        {
          dummyData?.items.map(item =>(
            <div className='cs-result-wrap'>
              <h3>{item?.title}</h3>
              <div className='cs-result-body'>
                <section>
                  <img src={item?.image} alt="img" />
                </section>

                <section>
                  <span><b>Metric :</b>{item?.metric}</span>
                  <span><b>Outcome :</b>{item?.outcome}</span>
                </section>
              </div>
            </div>
          ))
        }
      </div>

    </section>
  )
}

export default Results
